<template>
  <v-container fill-height>
    <v-row justify="center" align="center">
      <v-container>
        <v-row justify="center" class="mb-5">
          <h1>FAQ</h1>
        </v-row>
        <v-row
          justify="center"
          class="my-5"
          v-for="question in questions"
          :key="question.q"
        >
          <v-col align="center">
            <v-row style="max-width: 700px" class="px-4 text-left">
              <h2>{{ question.q }}</h2>
            </v-row>
            <v-row style="max-width: 700px" class="px-4 text-left">
              {{ question.a }}
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "FAQ",
  data: () => ({
    questions: [
      {
        q: "What is Baguette?",
        a:
          "Baguette is a marketplace for music collectibles. A place for artists to sell never-before-heard songs as 1/1 “virtually pressed records” which come in the form of NFT’s to the highest bidder.",
      },
      {
        q: "Who is Baguette for?",
        a:
          "Artists, musicians, singers, songwriters, producers, and all audio artists looking to mint NFT’s of their records that have never been released. But also collectors, fans or investors. Imagine holding the only key to reveal a song of your favorite artist. Fans can simply collect records and wait to open them or “break bread” and share the song with the world. Whether or not the record has been open, collectors have the ability to resell them on the secondary market. ",
      },
      {
        q: "How do I submit a song?",
        a:
          "All artists can submit through our contact page or by emailing submissions@baguette.cc Please include a brief description of who you are, the story behind the song and why the song should be sold on baguette. ",
      },
      {
        q: "What about commissions?",
        a:
          "Baguette takes a 15% commission (artist receives 85%) off the top of every auction. Artists are entitled to a 10% royalty each time the piece changes hands on the secondary market, while we collect a 3% processing fee. ",
      },
      {
        q: "What is a wallet?",
        a:
          "A wallet is what allows you to transact on the blockchain. Our platform allows you to import or create a Blocto wallet. It will hold all your digital assets, and will allow you to deposit or withdraw the digital token used for transactions: Flow.",
      },
      {
        q: "What is Flow?",
        a:
          "Baguette is built on Dapper Labs’ Flow Blockchain for a premiere NFT buying experience. Flow is a proof-of-stake blockchain that is designed for extensive scaling, providing fast and low-cost transactions",
      },
      {
        q: "What currency can be used on Baguette?",
        a:
          "Sales and auctions are done with ₣USD. ₣USD is the stablecoin on the Flow blockchain, with value 1 ₣USD = $1. To obtain such currency, you will need to exchange it on platforms like Blocto Swap. Make sure the address used on Blocto Swap is the same as the one of your linked account on Baguette, which can be found in your Account page. It can be exchanged there against Flow or tUSDT. tUSDT can be obtained by exchanging it against USDT.",
      },
      {
        q: "How do I set up my wallet?",
        a:
          "Once you log in to your account by following the magik link sent to your email address, you will be prompted to either connect or create a new wallet. Follow the guided steps and you will be able to log in and place a bid.",
      },
      {
        q: "How can I cash out?",
        a:
          "All your digital tokens and assets are stored in your Blocto wallet. Selling an asset will credit Flow tokens to your wallet which you can exchange on supported exchanges. You will not be able to cash out on the Baguette platform.",
      },
      {
        q: "How will I know if I win the auction?",
        a:
          "The asset will appear in your collection, which you can visualize in your account page. Please allow for a couple of minutes for our servers to update on any blockchain transaction.",
      },
      {
        q: "How do I receive my record?",
        a:
          "The record NFT will be sent to your Blocto wallet. If you decide to reveal the song, it will become public and everyone will be able to listen on the  built-in Baguette player.",
      },
      {
        q: "What happens when I reveal a song?",
        a:
          "Revealing a song will make the audio file public. Everyone will be able to listen to it once you \"break bread\". The record NFT will still be in your wallet as the only minted token of this song. What happens to the song next is up to the artist who can decide whether or not they want to upload to streaming platforms. One thing is certain, the file will always be accessible to be played even if the artist one day decides to remove it from streaming platforms.",
      },
    ],
  }),
  methods: {},
};
</script>